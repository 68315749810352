import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { useDownloadNanoId } from '../../../../../../downloads/hooks/useDownloadNanoId';

import { useDownloadMaterial } from '../../../../../hooks/useDownloadMaterial';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../../../locales/keys';

import { MaterialsListItemLightboxDownloadButtonMaterial } from './MaterialsListItemLightboxDownloadButton.types';

interface MaterialsListItemLightboxFavoriteButtonProps {
  material: MaterialsListItemLightboxDownloadButtonMaterial;
}

function MaterialsListItemLightboxFavoriteButton({
  material
}: MaterialsListItemLightboxFavoriteButtonProps) {
  const {
    downloadMaterialLoading,
    downloadMaterialErrorMessage,
    downloadMaterial
  } = useDownloadMaterial();

  useShowToastOnErrorChange({ error: downloadMaterialErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();
  const materialUuid = material.uuid;

  const handleClick = useCallback(() => {
    downloadMaterial({
      uuid: materialUuid,
      deviceNanoId: downloadNanoId
    });
  }, [materialUuid, downloadNanoId, downloadMaterial]);

  return (
    <PureTooltipIconButtonHelper
      className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
      dataGa="material-lightbox-download-button"
      icon={IconsEnum.DOWNLOAD_OUTLINE}
      iconClassName="h-6 w-6"
      tooltipSingleton
      tooltipI18nText={words.download}
      disabled={downloadMaterialLoading}
      onClick={handleClick}
    />
  );
}

export default MaterialsListItemLightboxFavoriteButton;
