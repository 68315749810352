import React, { memo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';
import {
  FetchMaterialsClearMaterialsFilters,
  FetchMaterialsFilterFields,
  FetchMaterialsFilters,
  FetchMaterialsFiltersToPersist,
  FetchMaterialsFilterMaterials,
  MaterialsOnResetFiltersAction
} from '../../materialsTypes';

import { BooleanFilterBadge } from '../../../../helpers/filterBadges/BooleanFilterBadge';
import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';
import { MaterialsBrandsFilterBadge } from '../filterBadges/MaterialsBrandsFilterBadge';

import { isEveryPropEmpty } from '../../../../utils/isEveryPropEmpty';

import { words } from '../../../../locales/keys';

interface MaterialsAppliedFiltersProps {
  clearMaterialsFilters: FetchMaterialsClearMaterialsFilters;
  onResetFilters?: MaterialsOnResetFiltersAction;
  materialsFilters: FetchMaterialsFilters;
  filterMaterials: FetchMaterialsFilterMaterials;
  filtersToPersist?: FetchMaterialsFiltersToPersist;
}

function MaterialsAppliedFilters({
  clearMaterialsFilters,
  materialsFilters,
  filterMaterials,
  filtersToPersist = []
}: MaterialsAppliedFiltersProps) {
  const persistantFilters: FetchMaterialsFiltersToPersist = [
    FetchMaterialsFilterFields.MATERIAL_CATEGORY_IDS,
    FetchMaterialsFilterFields.MATERIAL_PARENT_CATEGORY_IDS,
    ...filtersToPersist
  ];

  const withFilters = !isEveryPropEmpty(materialsFilters, persistantFilters);

  return (
    <div className="px-4">
      <div className="flex flex-wrap -m-0.5">
        <MaterialsBrandsFilterBadge
          filters={materialsFilters}
          name={FetchMaterialsFilterFields.BRAND_ID}
          onRemoveFilter={filterMaterials}
        />
        <BooleanFilterBadge
          dataGa="materials-blocked-filter-badge-clear"
          filters={materialsFilters}
          i18nLabel={words.blocked}
          name={FetchMaterialsFilterFields.BLOCKED}
          onRemoveFilter={filterMaterials}
        />
        <BooleanFilterBadge
          dataGa="materials-nda-filter-badge-clear"
          filters={materialsFilters}
          i18nLabel={words.nda}
          name={FetchMaterialsFilterFields.NDA}
          onRemoveFilter={filterMaterials}
        />
        {withFilters && (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-3 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 m-0.5"
            dataGa="materials-clear-filters-badge"
            i18nText={words.clearFilters}
            icon={IconsEnum.X}
            iconClassName="w-4 h-4"
            onClick={clearMaterialsFilters}
          />
        )}
      </div>
    </div>
  );
}

export default memo<MaterialsAppliedFiltersProps>(MaterialsAppliedFilters);
