import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import without from 'lodash/without';

import {
  FetchMaterialsFilters,
  ChangeMaterialsFiltersFunc
} from '../../../../../materialsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { MaterialsListItemBrandNameFilterButtonMaterial } from './MaterialsListItemBrandNameFilterButton.types';

interface MaterialsListItemBrandNameFilterButtonProps {
  material: MaterialsListItemBrandNameFilterButtonMaterial;
  materialsFilters?: FetchMaterialsFilters;
  changeMaterialsFilters?: ChangeMaterialsFiltersFunc;
}

function MaterialsListItemBrandNameFilterButton({
  material,
  materialsFilters,
  changeMaterialsFilters
}: MaterialsListItemBrandNameFilterButtonProps) {
  const brandId = material.brand?.id;

  const handleClick = useCallback<() => void>(() => {
    const currentBrandFilterIds = materialsFilters?.brandId?.in || [];

    if (includes(currentBrandFilterIds, brandId)) {
      const currentBrandFilterIdsWithoutMaterialBrand = without(
        currentBrandFilterIds,
        brandId
      );
      changeMaterialsFilters?.(
        {
          brandId: { in: currentBrandFilterIdsWithoutMaterialBrand }
        },
        isEmpty(currentBrandFilterIdsWithoutMaterialBrand) ? ['brandId'] : null
      );
    } else {
      changeMaterialsFilters?.({
        brandId: { in: [...currentBrandFilterIds, brandId] }
      });
    }
  }, [brandId, materialsFilters, changeMaterialsFilters]);

  if (!material.brand?.name) {
    return null;
  }
  return changeMaterialsFilters ? (
    <PureTooltipIconButtonHelper
      dataGa="materials-material-brand-name-filter-button"
      className="focus:ring-offset-0 focus:ring-0 hover:opacity-80"
      text={material.brand.name}
      tooltipText={material.brand.name}
      tooltipPlacement={TooltipPlacement.BOTTOM}
      icon={null}
      onClick={handleClick}
    />
  ) : (
    <span>{material.brand.name}</span>
  );
}

export default MaterialsListItemBrandNameFilterButton;
