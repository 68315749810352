import React, { memo } from 'react';

import {
  ChangeMaterialsFiltersFunc,
  FetchMaterialsFilters,
  FetchMaterialsTotalCount,
  MaterialsFiltersNavBasePath,
  MaterialsI18nCustomBaseTitle
} from '../../materialsTypes';

import useMaterialsIndexPageBreadcrumbs from './useMaterialsIndexPageBreadcrumbs';

import { MaterialsIndexPageBreadcrumbsItem } from './components/MaterialsIndexPageBreadcrumbsItem';

import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { MaterialPath } from '../../MaterialPath';

import { getChangeMaterialsFiltersCategoriesData } from '../../utils/getChangeMaterialsFiltersCategoriesData';

import { MaterialsPermissions } from '../../materialsConstants';
import { materialsKeys } from '../../../../locales/keys';

interface MaterialsIndexPageBreadcrumbsProps {
  filtersNavBasePath?: MaterialsFiltersNavBasePath;
  i18nCustomBaseTitle?: MaterialsI18nCustomBaseTitle;
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
  materialsTotalCount: FetchMaterialsTotalCount;
}

function MaterialsIndexPageBreadcrumbs({
  filtersNavBasePath,
  i18nCustomBaseTitle,
  materialsFilters,
  changeMaterialsFilters,
  materialsTotalCount
}: MaterialsIndexPageBreadcrumbsProps) {
  const {
    currentParentCategory,
    currentChildCategory,
    materialCategories,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  } = useMaterialsIndexPageBreadcrumbs({
    materialsFilters,
    changeMaterialsFilters
  });

  return (
    <div className="flex-1 truncate flex pl-3 pr-1 h-full items-center text-base xl:text-lg text-gray-500 space-x-1.5">
      <MaterialsIndexPageBreadcrumbsItem
        href={filtersNavBasePath}
        active={!currentChildCategory && !currentParentCategory}
        dataGa="materials-category-breadcrumb-materials"
        i18nText={i18nCustomBaseTitle || materialsKeys.plural}
        filtersNavBasePath={filtersNavBasePath}
        onChangeFilters={handleResetCategoriesFilters}
      />

      {currentParentCategory ? (
        <>
          {currentParentCategory.parent && (
            <>
              <span>/</span>
              <MaterialsIndexPageBreadcrumbsItem
                href={
                  filtersNavBasePath
                    ? MaterialPath.indexFiltersCustomBaseQuery(
                        filtersNavBasePath,
                        getChangeMaterialsFiltersCategoriesData({
                          materialCategory: currentParentCategory.parent,
                          categories: materialCategories
                        }).changeFilters
                      )
                    : '#'
                }
                dataGa={`materials-category-breadcrumb-${
                  currentParentCategory.parent.nameEn ||
                  currentParentCategory.parent.localizedName
                }`}
                active={false}
                text={currentParentCategory.parent.localizedName}
                filtersNavBasePath={filtersNavBasePath}
                onChangeFilters={handleParentCategoryChangeFilters}
              />
            </>
          )}

          <span>/</span>
          <MaterialsIndexPageBreadcrumbsItem
            dataGa={`materials-category-breadcrumb-${
              currentParentCategory.nameEn ||
              currentParentCategory.localizedName
            }`}
            href={
              filtersNavBasePath
                ? MaterialPath.indexFiltersCustomBaseQuery(
                    filtersNavBasePath,
                    getChangeMaterialsFiltersCategoriesData({
                      materialCategory: currentParentCategory,
                      categories: materialCategories
                    }).changeFilters
                  )
                : '#'
            }
            active={!currentChildCategory}
            text={currentParentCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
            onChangeFilters={handleParentCategoryChangeFilters}
          />
        </>
      ) : null}

      {currentChildCategory ? (
        <>
          <span>/</span>
          <MaterialsIndexPageBreadcrumbsItem
            active
            dataGa={`materials-category-breadcrumb-${
              currentChildCategory.nameEn || currentChildCategory.localizedName
            }`}
            text={currentChildCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
          />
        </>
      ) : null}

      {materialsTotalCount ? (
        <CheckPermissions
          action={MaterialsPermissions.READ_MATERIALS_TOTAL_COUNT}
        >
          <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
            {materialsTotalCount}
          </span>
        </CheckPermissions>
      ) : null}
    </div>
  );
}

export default memo<MaterialsIndexPageBreadcrumbsProps>(
  MaterialsIndexPageBreadcrumbs
);
