import React from 'react';
import {
  FetchMaterialsCacheKey,
  FetchMaterialsSort
} from '../../../materialsTypes';
import { FetchItemsSort } from '../../../../../types';

import { useMaterialsSortDropdown } from '../../../hooks/useMaterialsSortDropdown';

import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { DropdownHelper } from '../../../../../helpers/dropdowns/DropdownHelper';

import { words } from '../../../../../locales/keys';
import { materialsSortI18nTextTypes } from '../../../materialsConstants';

interface MaterialsFiltersDropdownButtonProps {
  currentSort: FetchItemsSort;
  materialsBaseCacheKey: FetchMaterialsCacheKey;
  sortMaterials: (nextSort: FetchMaterialsSort) => void;
}

function MaterialsSortDropdownButton({
  currentSort,
  materialsBaseCacheKey,
  sortMaterials
}: MaterialsFiltersDropdownButtonProps) {
  const { handleSortByMostLiked, handleSortByMostUsed, handleSortByNewest } =
    useMaterialsSortDropdown({
      materialsBaseCacheKey,
      sortMaterials
    });

  return (
    <DropdownHelper
      buttonClassName="py-1.5 pl-3 pr-3 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
      i18nText={materialsSortI18nTextTypes[currentSort.toString()]}
      dataGa="materials-sort-dropdown-button"
    >
      <PureButtonHelper
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        i18nText={words.mostLiked}
        dataGa="materials-most-liked-sort-button"
        onClick={handleSortByMostLiked}
      />
      <PureButtonHelper
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        i18nText={words.mostUsed}
        dataGa="materials-most-used-sort-button"
        onClick={handleSortByMostUsed}
      />
      <PureButtonHelper
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        dataGa="materials-newest-sort-button"
        i18nText={words.newest}
        onClick={handleSortByNewest}
      />
    </DropdownHelper>
  );
}

export default MaterialsSortDropdownButton;
