import React, { useCallback } from 'react';

import { PathUrl, I18nText } from '../../../../../../../types';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { MaterialsFiltersNavBasePath } from '../../../../../materialsTypes';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';

interface MaterialsIndexPageCategoriesNavHeadProps {
  i18nText?: I18nText;
  text?: string;
  backHref?: PathUrl;
  filtersNavBasePath?: MaterialsFiltersNavBasePath;
  onChangeFiltersBack?: () => void;
}

function MaterialsIndexPageCategoriesNavHead({
  i18nText,
  text,
  backHref,
  filtersNavBasePath,
  onChangeFiltersBack
}: MaterialsIndexPageCategoriesNavHeadProps) {
  const handleLinkClick = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (!filtersNavBasePath && onChangeFiltersBack) {
        e.preventDefault();
        onChangeFiltersBack();
      }
    },
    [filtersNavBasePath, onChangeFiltersBack]
  );

  return (
    <div className="-mx-4 mb-1">
      <div className="flex items-center group relative px-4 py-2">
        {backHref ? (
          <NextPureLinkHelper
            className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 -mx-1 -my-2"
            dataGa="materials-categories-filter-back"
            href={backHref}
            icon={IconsEnum.ARROW_LEFT_OUTLINE}
            iconClassName="h-6 w-6 stroke-2"
            onClick={handleLinkClick}
          />
        ) : null}
        <h6 className="flex-1 font-semibold uppercase px-2">
          {i18nText ? <Translate id={i18nText} /> : text}
        </h6>
      </div>
    </div>
  );
}

export default MaterialsIndexPageCategoriesNavHead;
