import React, { useCallback } from 'react';

import { UserID } from '../../../../users/usersTypes';
import {
  FetchMaterialsFilters,
  ChangeMaterialsFiltersFunc
} from '../../../materialsTypes';

import { Translate } from '../../../../../helpers/Translate';
import { ClientsSelectFilter } from '../../../../../helpers/filters/ClientsSelectFilter';

import { categoriesKeys } from '../../../../../locales/keys';

interface MaterialsClientFilterProps {
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

const name = 'clientIds' as const;

function MaterialsClientFilter({
  materialsFilters,
  changeMaterialsFilters
}: MaterialsClientFilterProps) {
  const handleChange = useCallback<(ids: UserID[]) => void>(
    (ids) => {
      changeMaterialsFilters({ [name]: ids }, ids ? undefined : [name]);
    },
    [changeMaterialsFilters]
  );

  const filterValue = materialsFilters?.[name];

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.client.select} />
        </h6>
      </div>
      <div className="flex flex-col space-y-2">
        <ClientsSelectFilter
          dataGa="materials-clients-filter"
          onChange={handleChange}
          value={filterValue}
        />
      </div>
    </div>
  );
}

export default MaterialsClientFilter;
