import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchMaterialsCacheKeys,
  UpdateMaterialCacheAction
} from '../../../../../materialsTypes';

import { FetchMaterialsSetsCacheKeys } from '../../../../../../materialsSets/materialsSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useMaterialsListItemLightboxFavoriteButton from './useMaterialsListItemLightboxFavoriteButton';

import { MaterialsListItemLightboxFavoriteButtonMaterial } from './MaterialsListItemLightboxFavoriteButton.types';

import { words } from '../../../../../../../locales/keys';

interface MaterialsListItemLightboxFavoriteButtonProps {
  material: MaterialsListItemLightboxFavoriteButtonMaterial;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  updateMaterialCache?: UpdateMaterialCacheAction<MaterialsListItemLightboxFavoriteButtonMaterial>;
}

function MaterialsListItemLightboxFavoriteButton({
  material,
  materialsCacheKeys,
  materialsSetCacheKeys,
  updateMaterialCache
}: MaterialsListItemLightboxFavoriteButtonProps) {
  const { handleToggleFavorite } = useMaterialsListItemLightboxFavoriteButton({
    material,
    materialsCacheKeys,
    materialsSetCacheKeys,
    updateMaterialCache
  });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white',
        material.favorite ? 'text-pink-600' : null
      )}
      dataGa="material-lightbox-favorite-button"
      icon={material.favorite ? IconsEnum.HEART_SOLID : IconsEnum.HEART_OUTLINE}
      iconClassName={cl('h-6 w-6', material.favorite ? 'p-0.5' : null)}
      tooltipSingleton
      tooltipI18nText={
        material.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      onClick={handleToggleFavorite}
    />
  );
}

export default MaterialsListItemLightboxFavoriteButton;
