import React, { useCallback, useMemo } from 'react';

import {
  FetchMaterialsFilters,
  ChangeMaterialsFiltersFunc
} from '../../../materialsTypes';

import { Translate } from '../../../../../helpers/Translate';
import {
  YesNoAnyRadioButtons,
  YesNoAnyRadioButtonsValue
} from '../../../../../helpers/YesNoAnyRadioButtons';

import { formsFields } from '../../../../../locales/keys';

interface MaterialsBlockedFilterProps {
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

const name = 'blocked' as const;

function MaterialsBlockedFilter({
  materialsFilters,
  changeMaterialsFilters
}: MaterialsBlockedFilterProps) {
  const handleChange = useCallback<(value: YesNoAnyRadioButtonsValue) => void>(
    (value) => {
      switch (value) {
        case YesNoAnyRadioButtonsValue.YES:
          changeMaterialsFilters({ [name]: true });
          break;
        case YesNoAnyRadioButtonsValue.NO:
          changeMaterialsFilters({ [name]: false });
          break;
        case YesNoAnyRadioButtonsValue.ANY:
          changeMaterialsFilters({}, [name]);
          break;
      }
    },
    [changeMaterialsFilters]
  );

  const value = useMemo(() => {
    switch (materialsFilters?.[name]) {
      case true:
        return YesNoAnyRadioButtonsValue.YES;
      case false:
        return YesNoAnyRadioButtonsValue.NO;
      default:
        return YesNoAnyRadioButtonsValue.ANY;
    }
  }, [materialsFilters]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={formsFields.blocked} />
        </h6>
      </div>
      <YesNoAnyRadioButtons
        dataGa="materials-blocked-filter"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default MaterialsBlockedFilter;
