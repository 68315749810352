import React, { useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

import { IdFilterFields } from '../../../../../types';
import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

import {
  FETCH_MATERIAL_BRANDS_SELECTED_QUERY,
  FetchMaterialBrandsSelectedQueryResponse
} from '../../../queries/fetchMaterialBrandsSelected.query';

import { useFinPaginatedCategories } from '../../../../categories/hooks/useFinPaginatedCategories';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { MaterialCache } from '../../../MaterialCache';
import { CACHE_FETCH_MATERIAL_BRANDS_SELECTED_QUERY } from '../../../queries/fetchCacheMaterialBrandsSelected.query';

interface MaterialsBrandsFilterBadgeItem {
  id: string;
  label: string;
}

const staleTime = 1000 * 60 * 60;

function MaterialsBrandsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const filterValue = useMemo(() => filters?.[name]?.in || [], [filters, name]);

  const {
    categories: materialBrandsSelected,
    changeCategoriesFilters: changeMaterialBrandsSelectedFilters,
    categoriesFilters: materialBrandsSelectedFilters
  } = useFinPaginatedCategories<FetchMaterialBrandsSelectedQueryResponse>({
    cacheKey: MaterialCache.brandsSelectedFilterCacheKey(),
    query: FETCH_MATERIAL_BRANDS_SELECTED_QUERY,
    cacheQuery: CACHE_FETCH_MATERIAL_BRANDS_SELECTED_QUERY,
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.MATERIAL_BRANDS },
      id: { in: filterValue }
    },
    options: {
      staleTime,
      keepPreviousData: true
    }
  });

  useEffect(() => {
    if (!isEqual(materialBrandsSelectedFilters.id?.in, filterValue)) {
      changeMaterialBrandsSelectedFilters({
        id: { in: filterValue }
      });
    }
  }, [
    changeMaterialBrandsSelectedFilters,
    materialBrandsSelectedFilters.id?.in,
    filterValue
  ]);

  const materialBrandsSelectedData = useMemo<MaterialsBrandsFilterBadgeItem[]>(
    () =>
      materialBrandsSelected.map((category) => ({
        id: category.id as string,
        label: category.name
      })),
    [materialBrandsSelected]
  );

  return (
    <ItemsFilterBadge
      dataGa="materials-brands-filter-badge-clear"
      filters={filters}
      i18nLabel={categoriesKeys.brands.plural}
      items={materialBrandsSelectedData}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default MaterialsBrandsFilterBadge;
