import React, { useCallback } from 'react';

import { PathUrl, I18nText, GADataType } from '../../../../../../types';

import { MaterialsFiltersNavBasePath } from '../../../../materialsTypes';

import { NextPureLinkHelper } from '../../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../../helpers/Translate';

interface MaterialsIndexPageBreadcrumbsItemProps {
  href?: PathUrl;
  active: boolean;
  i18nText?: I18nText;
  text?: string;
  filtersNavBasePath?: MaterialsFiltersNavBasePath;
  onChangeFilters?: () => void;
}

function MaterialsIndexPageBreadcrumbsItem({
  href,
  active,
  dataGa,
  i18nText,
  text,
  filtersNavBasePath,
  onChangeFilters
}: MaterialsIndexPageBreadcrumbsItemProps & GADataType) {
  const handleLinkClick = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (!filtersNavBasePath && onChangeFilters) {
        e.preventDefault();
        onChangeFilters();
      }
    },
    [filtersNavBasePath, onChangeFilters]
  );

  if (active) {
    return (
      <span className="font-medium text-black dark:text-white">
        {i18nText ? <Translate id={i18nText} /> : text}
      </span>
    );
  }

  return (
    <NextPureLinkHelper
      href={href || '#'}
      className="hover:underline"
      dataGa={dataGa}
      i18nText={i18nText}
      text={text}
      onClick={handleLinkClick}
    />
  );
}

export default MaterialsIndexPageBreadcrumbsItem;
